import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import logo from '../../assets/pantrylogo.png'
import { Icon, Input, Button, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import Footer from '../../Components/Footer'
import axios from 'axios'
import moment from 'moment'
import PDFDocument from '@react-pdf/pdfkit'
import blobStream from 'blob-stream'

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2")
}

class Invoice extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            loading: false,
            filteredInfo: null,
            sortedInfo: null,
            searchText: '',
            searchedColumn: '',
            tableData: [],
            tableData1: []
        }
    }

    componentWillMount() {
        const { user } = this.props
        const { tableData, tableData1 } = this.state
        if (!user || user.role !== 'admin') {
            this.props.history.replace('/login')
        }

        axios.post(`/api/get/bu-invoice-country`, {
            country: user.country
        })
            .then((res) => {
                const { data, success } = res.data
                if (success) {
                    for (var i in data) {
                        tableData.push({
                            key: i,
                            date: moment(data[i].create).format("LL"),
                            name: data[i].groceryName,
                            in_id: data[i]._id,
                            in_type: data[i].invoiceType,
                            action: data[i]
                        });
                    }
                    this.setState({ tableData })
                }
            })
            .catch((e) => console.log(e))

        axios.post(`/api/consumer/get/cu-invoice-country`, {
            country: user.country
        })
            .then((res) => {
                const { data, success } = res.data
                if (success) {
                    for (var i in data) {
                        tableData1.push({
                            key: i,
                            date1: moment(data[i].create).format("LL"),
                            name1: data[i].groceryName,
                            in_id1: data[i]._id,
                            action1: data[i]
                        });
                    }
                    this.setState({ tableData1 })
                }
            })
            .catch((e) => console.log(e))
    }

    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
        });
    };

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'ascend',
                columnKey: 'in_id',
            },
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    downloadPdf(v) {
        const { allData } = v
        const doc = new PDFDocument();
        const stream = doc.pipe(blobStream());

        doc.fontSize(16).font('Times-Roman').text('INVOICE', 180, 100)
        doc
            .fontSize(16)
            .font('Times-Roman')
            .text(`Voorschoten, ${moment(v.create).format('LL')}`, 280, 100);

        doc
            .font('Times-Bold')
            .text('From:', 10, 120);

        doc
            .font('Times-Roman')
            .text('Pantrywize', 10, 155);

        doc
            .text(`Wijngaardenlaan 17`, 10, 180);

        doc
            .text(`2252 XJ Voorschoten`, 10, 205);

        doc
            .font('Times-Bold')
            .text('To:', 10, 250);

        doc
            .font('Times-Roman')
            .text(v.buData.userName, 10, 280);

        doc
            .text(v.buData.streetAddress, 10, 310);

        doc
            .text(v.buData.city, 10, 340);

        doc
            .text(`Invoiceno: ${v._id}`, 10, 370);

        doc.text('Product Name', 10, 410);

        doc.text('Description', 150, 410);

        doc.text('Start', 280, 410);
        doc.text('End', 380, 410);
        doc.text('Total Price', 480, 410);

        doc.underline(10, 405, 600, 1.5)
        doc.underline(10, 426, 600, 1.5)

        var position = 435
        if (v.invoiceType === 'promoAd') {
            doc.fontSize(14).text(allData.name.slice(0, 15), 10, position);
            doc.text(allData.description.slice(0, 10), 150, position);
            doc.text(moment(allData.startDate).format('L'), 280, position);
            doc.text(moment(allData.endDate).format('L'), 380, position);
            doc.text(`€ ${allData.budget}`, 480, position)

            doc.text('Total ex BTW:', 380, position + 80)
            doc.text(`€ ${allData.budget}`, 480, position + 80)
            doc.text('BTW 21 %:', 380, position + 120)
            doc.text(`€ ${roundToTwo(21 / 100 * allData.budget)}`, 480, position + 120)
            doc.underline(480, position + 160, 70, 1.5)
            doc.text('Total incl BTW:', 380, position + 200)
            doc.text(`€ ${allData.budget + roundToTwo(21 / 100 * allData.budget)}`, 480, position + 200)
        }
        else {
            doc.fontSize(14).text(allData.subscription.slice(0, 15), 10, position);
            doc.text(allData.description.slice(0, 10), 150, position);
            doc.text(moment(allData.startDate).format('L'), 280, position);
            doc.text(moment(allData.endDate).format('L'), 380, position);
            doc.text(`€ ${allData.price}`, 480, position)

            doc.text('Total ex BTW:', 380, position + 80)
            doc.text(`€ ${allData.price}`, 480, position + 80)
            doc.text('BTW 21 %:', 380, position + 120)
            doc.text(`€ ${roundToTwo(21 / 100 * allData.price)}`, 480, position + 120)
            doc.underline(480, position + 160, 70, 1.5)
            doc.text('Total incl BTW:', 380, position + 200)
            doc.text(`€ ${allData.price + roundToTwo(21 / 100 * allData.price)}`, 480, position + 200)
        }

        doc.end();
        stream.on('finish', function () {
            var url = stream.toBlobURL('application/pdf');
            window.open(url, '_blank')
        });
    }

    downloadPdf1(v) {
        const { allData } = v
        const doc = new PDFDocument();
        const stream = doc.pipe(blobStream());

        doc.fontSize(16).text('Invoice', 10, 50)
        doc
            .fontSize(16)
            .font('Times-Roman')
            .text(moment(v.create).format('LL'), 10, 100);

        doc
            .font('Times-Bold')
            .text('Supplier:', 10, 120);

        doc
            .font('Times-Roman')
            .text(v.buData.userName, 10, 150);

        doc
            .text(`${v.buData.streetAddress} ${v.buData.houseNo} ${v.buData.houseNoExtra}`, 10, 170);

        doc
            .text(`${v.buData.postalCode} ${v.buData.city}`, 10, 190);

        doc
            .text(v.country, 10, 210);

        doc
            .font('Times-Bold')
            .text('Client:', 10, 230);

        doc
            .font('Times-Roman')
            .text(v.cuName, 10, 250);

        doc
            .font('Times-Bold')
            .text('Products:', 10, 280);

        doc
            .font('Times-Roman')
            .text('Input Number', 10, 310);

        doc.text('Product Name', 120, 310);

        doc.text('Promo Text', 250, 310);

        doc.text('Unit Sold', 350, 310);
        doc.text('Price', 430, 310);
        doc.text('Total Price', 480, 310);

        doc.underline(10, 326, 600, 1.5)
        doc.underline(10, 305, 600, 1.5)

        var position = 335
        var totalPrice = 0
        var productPrice = 0
        for (var obj of allData) {
            productPrice = Math.ceil(obj.inputNumber * obj.condition) * obj['current_price']
            doc.text(`${obj.inputNumber}`, 10, position);
            doc.text(obj.product_title.slice(0, 15), 120, position);
            doc.text(obj['orange_block_info/promoText'].slice(0, 10), 250, position);
            doc.text(obj['unit_sold'], 350, position);
            doc.text(`€ ${obj['current_price']}`, 430, position);
            doc.text(`€ ${productPrice}`, 480, position);

            position = position + 20
            totalPrice = totalPrice + productPrice
        }

        doc.text('Total:', 430, position + 30)
        doc.text(`€ ${totalPrice}`, 480, position + 30)
        doc.text('BTW 9%', 410, position + 60)
        doc.text(`€ ${roundToTwo(9 / 109 * totalPrice)}`, 480, position + 60)
        // doc.underline(480, position + 90, 70, 1.5)
        // doc.text(`Total incl BTW: `, 365, position + 110)
        // doc.text(`€ ${roundToTwo((9 / 109 * totalPrice) + totalPrice)}`, 480, position + 110)

        doc.end();
        stream.on('finish', function () {
            var url = stream.toBlobURL('application/pdf');
            window.open(url, '_blank')
        });
    }

    render() {
        let { sortedInfo, tableData, tableData1 } = this.state;
        sortedInfo = sortedInfo || {};

        const columns = [
            {
                title: 'Invoice Id',
                dataIndex: 'in_id',
                ellipsis: true,
                sorter: (a, b) => a.in_id.length - b.in_id.length,
                sortOrder: sortedInfo.columnKey === 'in_id' && sortedInfo.order
            },
            {
                title: 'Invoice Type',
                dataIndex: 'in_type',
                ellipsis: true,
                sorter: (a, b) => a.in_type.length - b.in_type.length,
                sortOrder: sortedInfo.columnKey === 'in_type' && sortedInfo.order
            },
            {
                title: 'Date',
                dataIndex: 'date',
                ellipsis: true,
                sorter: (a, b) => a.date.length - b.date.length,
                sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order
            },
            {
                title: 'Operation',
                dataIndex: 'action',
                ellipsis: true,
                render: (v) => <div style={{
                    width: '100%',
                    paddingLeft: 50,
                    paddingRight: 50,
                    display: 'flex',
                    justifyContent: 'space-around'
                }}>
                    <Icon type="download" onClick={() => this.downloadPdf(v)} style={{ marginRight: 10 }} />
                    <Icon type="delete" />
                </div>
            }
        ];

        const columns1 = [
            {
                title: 'Invoice Id',
                dataIndex: 'in_id1',
                ellipsis: true,
                sorter: (a, b) => a.in_id1.length - b.in_id1.length,
                sortOrder: sortedInfo.columnKey === 'in_id1' && sortedInfo.order
            },
            {
                title: 'Name',
                dataIndex: 'name1',
                ellipsis: true,
                sorter: (a, b) => a.name1.length - b.name1.length,
                sortOrder: sortedInfo.columnKey === 'name1' && sortedInfo.order
            },
            {
                title: 'Date',
                dataIndex: 'date1',
                ellipsis: true,
                sorter: (a, b) => a.date1.length - b.date1.length,
                sortOrder: sortedInfo.columnKey === 'date1' && sortedInfo.order
            },
            {
                title: 'Operation',
                dataIndex: 'action1',
                ellipsis: true,
                render: (v) => <div style={{
                    width: '100%',
                    paddingLeft: 50,
                    paddingRight: 50,
                    display: 'flex',
                    justifyContent: 'space-around'
                }}>
                    <Icon type="download" onClick={() => this.downloadPdf1(v)} style={{ marginRight: 10 }} />
                    <Icon type="delete" />
                </div>
            }
        ];
        return (
            <div>
                <div className="advertisement2">
                </div>

                <header className="header2 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                            <div style={{ display: 'flex' }}>
                                <Link className="home-btn" to="/"><i className="fas fa-home"></i></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">

                                <ul className="navbar-nav ml-auto">

                                    <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Settings
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <Link className="dropdown-item" to="/grocery">Grocery</Link>
                                            <Link className="dropdown-item" to="/offer-type">OfferType</Link>
                                            <Link className="dropdown-item" to="/topic">Topic</Link>
                                            <Link className="dropdown-item" to="/user-group">UserGroup</Link>
                                            <Link className="dropdown-item" to="/product">Product</Link>
                                            <Link className="dropdown-item" to="/product-group">ProductGroup</Link>
                                            <Link className="dropdown-item" to="/product-type">ProductType</Link>
                                            <Link className="dropdown-item" to="/qanda">Question & Answer settings </Link>
                                            <Link className="dropdown-item" to="/focusgroup">Focusgroup settings</Link>
                                            <Link className="dropdown-item" to="/1to1">1 To 1 Offer settings</Link>
                                            <Link className="dropdown-item" to="/ad">Promotional Ads settings</Link>
                                            <Link className="dropdown-item" to="/faq">FAQ</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/subscription">Subscriptions</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/insight">Insights</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>

                <div className="main-content-bg">
                    <section>
                        <section className="main-content-bg2">
                            <div className="center1">
                                <div className="card2">
                                    <h2><b>OVERVIEW</b></h2>
                                    <br />
                                    <h4><b>BU Invoices</b></h4>
                                    <br />
                                    <br />
                                    <Table
                                        className="components-table-demo-nested"
                                        onChange={this.handleChange}
                                        columns={columns}
                                        dataSource={tableData}
                                        bordered
                                        title={() => <div className="table-operations">
                                            <Button onClick={this.setAgeSort}>Sort Invoice-id</Button>
                                            <Button onClick={this.clearAll}>Clear sorters</Button>
                                        </div>}
                                        style={{ width: '100%' }}
                                        tableLayout="fixed"
                                        scroll={{ y: 300 }}
                                    />

                                    <br />
                                    <br />
                                    <h2><b>OVERVIEW</b></h2>
                                    <br />
                                    <h4><b>CU Invoices</b></h4>
                                    <br />
                                    <br />
                                    <Table
                                        className="components-table-demo-nested"
                                        onChange={this.handleChange}
                                        columns={columns1}
                                        dataSource={tableData1}
                                        bordered
                                        title={() => <div className="table-operations">
                                            <Button onClick={this.setAgeSort}>Sort Invoice-id</Button>
                                            <Button onClick={this.clearAll}>Clear sorters</Button>
                                        </div>}
                                        style={{ width: '100%' }}
                                        tableLayout="fixed"
                                        scroll={{ y: 300 }}
                                    />
                                </div>
                            </div>
                        </section>

                        <Footer {...this.props} />
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Invoice)