import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Upload, Form, Select, Input, Icon, DatePicker } from 'antd';
import SubmitButton from '../../Components/SubmitButton'
import { loginUser } from '../../Redux/actions/authActions'
import moment from 'moment'

const { Option } = Select
const { TextArea } = Input;
const saleType = ["A", "B", "C"]

const props = {
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    listType: 'picture',
};

class FormModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disable: false,
            fileList: []
        }
    }

    disabledEndDate(current) {
        return current < moment(this.state.StartDateValue).add(1, 'day')
    }

    disabledStartDate(current) {
        return current > moment(this.state.EndDateValue)
    }

    updateSelected(e) {
        const { selected } = this.state
        this.setState({ selected: { ...selected, answers: e } })
    }

    handleOk = () => {
        const { selected } = this.state
        const { openNotification, handleOk } = this.props
        if (selected.answers.length) {
            handleOk(selected)
        }
        else {
            openNotification('Error', 'Please Select atleast one', 'close-circle', 'red')
        }
    }

    render() {
        const { loading, disable } = this.state
        const { getFieldDecorator } = this.props.form;
        const { visible, handleCancel, question, answers } = this.props
        return (
            <div>
                <Modal
                    visible={visible}
                    title="Question"
                    onOk={this.handleOk}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            Return
                    </Button>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            Submit
                    </Button>,
                    ]}
                >
                    <Form
                        // onSubmit={this.handleSubmit}
                        className="login-form"
                        hideRequiredMark={true}
                        encType="multipart/form-data">
                        <h1 className="heading1" >1 To 1 Offers Settings</h1>
                        <Form.Item
                            label="Name"
                        >
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please input Name!' }],
                            })(
                                <Input
                                    type="text"
                                    minLength={3}
                                    placeholder="1 To 1 offer name"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Focusgroup"
                        >
                            {getFieldDecorator('focusGroup', {
                                rules: [{ required: true, message: 'Please Select Focusgroup Name!' }],
                            })(
                                <Select
                                    showSearch
                                    mode="multiple"
                                    style={{ backgroundColor: '#fff' }}
                                    placeholder="Select Focusgroup Name"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        saleType.map((v, i) => {
                                            return <Option value={v} key={i}>{v}</Option>
                                        })
                                    }
                                </Select>,
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Offer Type"
                        >
                            {getFieldDecorator('Offertype', {
                                rules: [{ required: true, message: 'Please Select Offer Type!' }],
                            })(
                                <Select
                                    showSearch
                                    style={{ backgroundColor: '#fff' }}
                                    placeholder="Select Offer Type"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        saleType.map((v, i) => {
                                            return <Option value={v} key={i}>{v}</Option>
                                        })
                                    }
                                </Select>,
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Upload Image"
                        >
                            {getFieldDecorator('image', {
                                rules: [{ required: true, message: 'Please upload Image!' }],
                            })(
                                <Upload {...props}
                                    onChange={this.normFile}
                                    fileList={this.state.fileList}
                                    onRemove={() => this.setState({ fileList: [] })}
                                    accept="image/*">
                                    <Button disabled={this.state.fileList.length ? true : false} >
                                        <Icon type="upload" /> Upload
                                                    </Button>
                                </Upload>,
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Start Date"
                        >
                            {getFieldDecorator('startDate', {
                                rules: [{ required: true, message: 'Please input Sold Price!' }],
                            })(
                                <DatePicker
                                    showTime={false}
                                    onChange={(e) => this.setState({ StartDateValue: e })}
                                    format="YYYY-MM-DD"
                                    disabledDate={this.disabledStartDate.bind(this)}
                                    placeholder="Start Date"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item
                            label="End Date"
                        >
                            {getFieldDecorator('endDate', {
                                rules: [{ required: true, message: 'Please Select Sale Type!' }],
                            })(
                                <DatePicker
                                    showTime={false}
                                    onChange={(e) => this.setState({ EndDateValue: e })}
                                    format="YYYY-MM-DD"
                                    placeholder="End Date"
                                    disabledDate={this.disabledEndDate.bind(this)}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Description"
                        >
                            {getFieldDecorator('description', {
                                rules: [{ required: true, message: 'Please input description!' }],
                            })(
                                <TextArea rows={4} placeholder="Input Description Here!!!" />,
                            )}
                        </Form.Item>

                        <Form.Item>
                            <SubmitButton loading={loading} disable={disable} />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

const FormModalForm = Form.create({ name: 'normal_login' })(FormModal);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(FormModalForm)