import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import { Form, Icon, Input, Button, message } from 'antd';
import logo from '../../assets/pantrylogo.png'
import Footer from '../../Components/Footer'
import axios from 'axios'



class Forget extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disable: false,
            googleDisable: false,
            success: false,
            newPassword: false,
            email: ''
        }
    }

    handleSubmit = e => {
        const { validateFields } = this.props.form
        e.preventDefault();

        validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true, disable: true })
                axios.post('/api/admin/user/forgot-password', values)
                    .then((result) => {
                        const { data } = result
                        if (data.success) {
                            this.setState({ loading: false, disable: false, success: true, email: values.email })
                            message.success(data.message)
                        }
                        else {
                            this.setState({ loading: false, disable: false })
                            message.error(data.message)
                        }
                    })
                    .catch((err) => {
                        this.setState({ loading: false, disable: false })
                        message.error('Oops Something Went Wrong!')
                    })
            }
        });
    };

    handleSubmitVerify = e => {
        const { validateFields } = this.props.form
        const { email } = this.state
        e.preventDefault();

        validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true, disable: true })
                axios.post(`/api/admin/user/verify-code`, {
                    otp: values.code,
                    email
                })
                    .then((result) => {
                        const { data } = result
                        if (data.success) {
                            this.setState({ loading: false, disable: false, newPassword: true })
                            message.success(data.message)
                        }
                        else {
                            this.setState({ loading: false, disable: false })
                            message.error(data.message)
                        }
                    })
                    .catch((err) => {
                        this.setState({ loading: false, disable: false })
                        message.error('Something went wrong, please try again!')
                    })
            }
        });
    };

    handleSubmitPassword = e => {
        const { email } = this.state
        const { validateFields } = this.props.form
        e.preventDefault();

        validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true, disable: true })
                axios.post(`/api/admin/user/update-password`, {
                    email,
                    password: values.password
                })
                    .then((result) => {
                        const { data } = result
                        if (data.success) {
                            const { loginUser } = this.props
                            loginUser(data.user)
                            setTimeout(() => {
                                this.setState({ loading: false, disable: false })
                                this.props.history.push('/')
                            }, 500)
                        }
                        else {
                            this.setState({ loading: false, disable: false })
                            message.error(data.message)
                        }
                    })
                    .catch((err) => {
                        this.setState({ loading: false, disable: false })
                        message.error('Something Went Wrong!')
                    })
            }
        });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    render() {
        const { disable, loading, success, newPassword } = this.state
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div className="advertisement">
                </div>
                <header className="header1 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <a className="navbar-brand" href="#"><img src={logo} alt="logo" className="img-fluid" /></a>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">
                            </div>
                        </div>
                    </nav>
                </header>
                <div className="main-content-bg">

                    <div id="contact"></div>


                    <section className="contact">
                        <div className="center1">
                            <div className="card2">
                                {success && newPassword ? <Form
                                    hideRequiredMark={true}
                                    onSubmit={this.handleSubmitPassword}
                                    className="login-form">
                                    <Form.Item
                                        label="Password"
                                    >
                                        {getFieldDecorator('password', {
                                            rules: [{ required: true, message: 'Please input your Password!' }],
                                        })(
                                            <Input
                                                style={{ width: 400 }}
                                                type="password"
                                                placeholder="Password"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Confirm Password"
                                    >
                                        {getFieldDecorator('confirm', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please Confirm your Password!'
                                                },
                                                {
                                                    validator: this.compareToFirstPassword,
                                                }
                                            ],
                                        })(
                                            <Input
                                                style={{ width: 400 }}
                                                type="password"
                                                placeholder="Confirm Password"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="submit-btn">
                                            <Button htmlType="submit" loading={loading} disabled={disable} className="btn" style={{ backgroundColor: '#0587EF', color: 'white', fontWeight: 'bold', fontSize: 14, height: 50, display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                                                Submit
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </Form> : success && !newPassword ? <Form
                                    hideRequiredMark={true}
                                    onSubmit={this.handleSubmitVerify}
                                    className="login-form">
                                    <Form.Item
                                        label={<p className="label-login" style={{ marginBottom: -40 }}>Verification Code</p>}
                                    >
                                        {getFieldDecorator('code', {
                                            rules: [{ required: true, message: 'Please input Verification Code!' }],
                                        })(
                                            <Input
                                                style={{ width: 400 }}
                                                placeholder="Enter Verification Code"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="submit-btn">
                                            <Button htmlType="submit" loading={loading} disabled={disable} className="btn" style={{ backgroundColor: '#0587EF', color: 'white', fontWeight: 'bold', fontSize: 14, height: 50, display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                                                Verify
                                                    </Button>
                                        </div>
                                    </Form.Item>
                                </Form> : <Form
                                    hideRequiredMark={true}
                                    onSubmit={this.handleSubmit}
                                    className="login-form">
                                            <h3 style={{ color: '#fff' }}>Forgot Password</h3>
                                            <Form.Item
                                                label={<p className="label-login" style={{ marginBottom: -40 }}>Email</p>}
                                            >
                                                {getFieldDecorator('email', {
                                                    rules: [{ required: true, message: 'Please input your Email!' }],
                                                })(
                                                    <Input
                                                        style={{ width: 400 }}
                                                        placeholder="Enter your Email."
                                                        type="email"
                                                    />,
                                                )}
                                            </Form.Item>
                                            <Form.Item>
                                                <Form.Item>
                                                    <div className="submit-btn">
                                                        <Button htmlType="submit" loading={loading} disabled={disable} className="btn" style={{ backgroundColor: '#0587EF', color: 'white', fontWeight: 'bold', fontSize: 14, height: 50, display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                                                            Get Code
                                                    </Button>
                                                    </div>
                                                </Form.Item>
                                                <span>Already have account? </span><Link to="/login" className="login-form-forgot textColor">Login</Link>
                                            </Form.Item>
                                        </Form>}
                            </div>
                        </div>
                    </section>
                </div>
                <Footer {...this.props} />
            </div>
        )
    }
}

const ForgetForm = Form.create({ name: 'normal_login' })(Forget);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(ForgetForm)