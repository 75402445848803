import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { Form, Icon, Input, Button, notification } from 'antd';
import logo from '../../assets/pantrylogo.png'
import Footer from '../../Components/Footer'
import axios from 'axios'

const title = "Error"



class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disable: false
        }
    }

    componentWillMount() {
        this.props.removeUser()
    }

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    handleSubmit = e => {
        const { user, form } = this.props
        const { validateFields } = form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                this.setState({ disable: true, loading: true })
                axios.post('/api/admin/user/login', values)
                    .then((result) => {
                        const { data } = result
                        if (data.success) {
                            this.openNotification('Success', data.message, 'check')
                            this.props.loginUser(data.data)
                            setTimeout(() => {
                                this.setState({ disable: false, loading: false })
                                if (data.data.role === 'superadmin') {
                                    this.props.history.replace('/register')
                                }
                                else {
                                    this.props.history.replace('/')
                                }
                            }, 500)
                        }
                        else {
                            this.openNotification(title, data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    };

    render() {
        const { loading, disable } = this.state
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div className="advertisement">
                </div>
                <header className="header1 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <a className="navbar-brand" href="#"><img src={logo} alt="logo" className="img-fluid" /></a>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">
                            </div>
                        </div>
                    </nav>
                </header>
                <div className="main-content-bg">

                    <div id="contact"></div>


                    <section className="contact">
                        <div className="center1">
                            <div className="card2">
                                <Form
                                    className="login-form form-w"
                                    hideRequiredMark={true}
                                    onSubmit={this.handleSubmit}
                                    encType="multipart/form-data"
                                >
                                    <h1 className="heading1" >Log In</h1>
                                    <Form.Item
                                        label="Email"
                                    >
                                        {getFieldDecorator('email', {
                                            rules: [{ required: true, message: 'Please input Email!' }],
                                        })(
                                            <Input
                                                type="email"
                                                placeholder="Email"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Password"
                                    >
                                        {getFieldDecorator('password', {
                                            rules: [{ required: true, message: 'Please input Password!' }],
                                        })(
                                            <Input.Password
                                                minLength={9}
                                                placeholder="Password"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Link className="login-form-forgot" to="/forgot">
                                        Forgot password
                                    </Link>
                                    <Form.Item>
                                        <div className="submit-btn">
                                            <Button htmlType="submit" loading={loading} disabled={disable} className="btn" style={{ backgroundColor: '#0587EF', color: 'white', fontWeight: 'bold', fontSize: 14, height: 50, display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                                                Log In
                                        </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer {...this.props} />
            </div>
        )
    }
}

const LoginForm = Form.create({ name: 'normal_login' })(Login);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
        removeUser: () => dispatch(removeUser())
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)