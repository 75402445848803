import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import { Form, Icon, Input, Button, Select, Table, notification, Popconfirm, Switch } from 'antd';
import logo from '../../assets/pantrylogo.png'
import Highlighter from 'react-highlight-words';
import Footer from '../../Components/Footer'
import axios from 'axios'
import moment from 'moment'
import InspectModal from './Inspect'

var expandData = {}

const saleType = ["A", "B", "C"]
const title = "Error"

const { Option } = Select



class Qanda extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            loading: false,
            disable: false,
            filteredInfo: null,
            sortedInfo: null,
            searchText: '',
            searchedColumn: '',
            tableData: [],
            tableData1: [],
            allData: [],
            topicData: [],
            buSwitch: false,
            cuSwitch: false,
            showInspect: false,
            inspectValue: {}

        }
    }

    componentWillMount() {
        const { user } = this.props
        let { tableData, topicData, tableData1 } = this.state

        if (!user || user.role !== 'admin') {
            this.props.history.replace('/login')
        }
        axios.get(`/api/admin/get/get-topic/${user._id}`)
            .then((res) => {
                const { data } = res.data
                for (var i of data) {
                    if (i.buSwitch) {
                        topicData.push(i.name)
                    }
                }
                topicData = topicData.filter((v, i, a) => a.indexOf(v) === i);
                this.setState({ topicData })
            })
            .catch((e) => console.log(e))
        axios.get(`/api/admin/get/get-question/${user._id}`)
            .then((res) => {
                const { data } = res.data
                for (var i in data) {
                    tableData.push({
                        key: data[i]._id,
                        name: data[i].name,
                        topic: data[i].topic[0],
                        tAns: Boolean(data[i].ans1.length) + Boolean(data[i].ans2.length) + Boolean(data[i].ans3.length) + Boolean(data[i].ans4.length) + Boolean(data[i].ans5.length),
                        index: parseInt(i),
                        bu: data[i].buSwitch ? 'True' : 'False',
                        cu: data[i].cuSwitch ? 'True' : 'False',
                        action: data[i],
                        delete: data[i]._id
                    })
                }
                this.setState({ tableData, allData: data })
            })
            .catch((e) => console.log(e))

        axios.post(`/api/post/get-one-country`, { country: user.country })
            .then((res) => {
                const { data } = res.data
                for (var i in data) {
                    console.log('data[i]', data[i])
                    tableData1.push({
                        key: data[i]._id,
                        name1: data[i].name,
                        topic1: data[i].topic[0],
                        tAns1: Boolean(data[i].ans1.length) + Boolean(data[i].ans2.length) + Boolean(data[i].ans3.length) + Boolean(data[i].ans4.length) + Boolean(data[i].ans5.length),
                        index: parseInt(i),
                        action1: data[i],
                        inspect: data[i],
                        delete1: data[i]._id,
                    })
                }
                this.setState({ tableData1 })
            })
            .catch((e) => console.log(e))
    }

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
        });
    };

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'ascend',
                columnKey: 'name',
            },
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    expandedRowRender = () => {
        const columns = [
            {
                title: 'Topics',
                ellipsis: true,
                children: [
                    {
                        title: 'Topic 2',
                        dataIndex: 'topic2',
                        ellipsis: true,
                    },
                    {
                        title: 'Topic 3',
                        dataIndex: 'topic3',
                        ellipsis: true,
                    },
                    {
                        title: 'Topic 4',
                        dataIndex: 'topic4',
                        ellipsis: true,
                    }
                ]
            },
            {
                title: 'Total Answers',
                ellipsis: true,
                children: [
                    {
                        title: 'Answer 1',
                        dataIndex: 'ans1',
                        ellipsis: true,
                    },
                    {
                        title: 'Answer 2',
                        dataIndex: 'ans2',
                        ellipsis: true,
                    },
                    {
                        title: 'Answer 3',
                        dataIndex: 'ans3',
                        ellipsis: true,
                    },
                    {
                        title: 'Answer 4',
                        dataIndex: 'ans4',
                        ellipsis: true,
                    },
                    {
                        title: 'Answer 5',
                        dataIndex: 'ans5',
                        ellipsis: true,
                    }
                ]
            }
        ]
        const data = [{
            topic2: expandData.topic[1] ? expandData.topic[1] : '',
            topic3: expandData.topic[2] ? expandData.topic[2] : '',
            topic4: expandData.topic[3] ? expandData.topic[3] : '',
            ans1: expandData.ans1,
            ans2: expandData.ans2,
            ans3: expandData.ans3,
            ans4: expandData.ans4,
            ans5: expandData.ans5,
        }]
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    handleSubmit = e => {
        const { user, form } = this.props
        const { resetFields, validateFields } = form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                this.setState({ disable: true, loading: true })
                values.userId = user._id
                values.country = user.country
                axios.post('/api/admin/post/add-question', values)
                    .then((result) => {
                        if (result.data.success) {
                            this.openNotification('Success', result.data.message, 'check')
                            this.setState({ disable: false, loading: false })
                            resetFields()
                            window.location.reload()
                        }
                        else {
                            this.openNotification(title, result.data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    };

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    handleExpand = (row) => {
        const { allData } = this.state
        expandData = allData[row.index]
    }

    createCopyData(v) {
        const { setFieldsValue } = this.props.form
        let { name, topic, ans1, ans2, ans3, ans4, ans5, question, buSwitch, cuSwitch } = v
        setFieldsValue({
            name,
            topic,
            ans1,
            ans2,
            ans3,
            ans4,
            ans5,
            question,
            buSwitch,
            cuSwitch
        })
        this.setState({ buSwitch, cuSwitch })
        this.openNotification('Success', 'Successfully Copy Data', 'check')
    }

    checkLimit(name) {
        const { getFieldValue, setFieldsValue } = this.props.form
        if (getFieldValue(name).length > 4) {
            this.openNotification('Error', 'You can only select Maximun 4!!!', 'close-circle', 'red')
            setFieldsValue({
                [name]: getFieldValue(name).slice(0, 4)
            })

            return false
        }

        return true
    }

    async deleteData(v) {
        let { tableData } = this.state
        await axios.post(`/api/admin/post/qanda-delete`, { id: v })
            .then((res) => {
                const { data } = res
                if (data.success) {
                    tableData = tableData.filter(i => i.key !== v)
                    this.setState({ tableData })
                    this.openNotification('Success', 'Successfully Deleted Data', 'check')
                }
                else {
                    this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
                }
            })
            .catch((e) => {
                this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
            })
    }

    approvedData(v) {
        let { tableData1 } = this.state
        axios.get(`/api/get/approved-one/${v._id}`)
            .then((result) => {
                const { data } = result
                if (data.success) {
                    tableData1 = tableData1.filter(i => i.key !== v._id)
                    this.setState({ tableData1 })
                    this.openNotification('Success', 'Successfully Approved', 'check')
                }
                else {
                    this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
                }
            })
            .catch((e) => {
                this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
            })
    }

    async rejectData(v) {
        let { tableData1 } = this.state
        await axios.get(`/api/get/delete-one/${v}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    tableData1 = tableData1.filter(i => i.key !== v)
                    this.setState({ tableData1 })
                    this.openNotification('Success', 'Successfully Deleted Data', 'check')
                }
                else {
                    this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
                }
            })
            .catch((e) => {
                this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
            })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let { sortedInfo, filteredInfo, loading, disable, tableData, topicData, tableData1, buSwitch, cuSwitch, inspectValue, showInspect } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'Q & A Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'BU',
                dataIndex: 'bu',
                ellipsis: true
            },
            {
                title: 'CU',
                dataIndex: 'cu',
                ellipsis: true
            },
            {
                title: 'Topic 1',
                dataIndex: 'topic',
                sorter: (a, b) => a.topic.length - b.topic.length,
                sortOrder: sortedInfo.columnKey === 'topic' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('topic'),
            },
            {
                title: 'Total',
                ellipsis: true,
                children: [
                    {
                        title: 'Answer',
                        dataIndex: 'tAns',
                        ellipsis: true,
                    }
                ]
            },
            {
                title: 'Operation',
                dataIndex: 'action',
                ellipsis: true,
                render: (v) => <Button onClick={() => this.createCopyData(v)}>Create</Button>
            },
            {
                title: 'Delete',
                dataIndex: 'delete',
                ellipsis: true,
                render: (v) => <Popconfirm
                    title="Are you sure delete this data?"
                    onConfirm={() => this.deleteData(v)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="danger">Delete</Button>
                </Popconfirm>
            }
        ];

        const columns1 = [
            {
                title: 'Q & A Name',
                dataIndex: 'name1',
                sorter: (a, b) => a.name1.length - b.name1.length,
                sortOrder: sortedInfo.columnKey === 'name1' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('name1'),
            },
            {
                title: 'Topic 1',
                dataIndex: 'topic1',
                sorter: (a, b) => a.topic1.length - b.topic1.length,
                sortOrder: sortedInfo.columnKey === 'topic1' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('topic1'),
            },
            {
                title: 'Total1',
                ellipsis: true,
                children: [
                    {
                        title: 'Answer',
                        dataIndex: 'tAns1',
                        ellipsis: true,
                    }
                ]
            },
            {
                title: 'Action',
                dataIndex: 'action1',
                ellipsis: true,
                render: (v) => <Button onClick={() => this.approvedData(v)}>Approve</Button>
            },
            {
                title: 'Inspect',
                dataIndex: 'inspect',
                ellipsis: true,
                render: (v) => <Button onClick={() => this.setState({ showInspect: true, inspectValue: v })} type="primary">Inspect</Button>
            },
            {
                title: 'Reject',
                dataIndex: 'delete1',
                ellipsis: true,
                render: (v) => <Popconfirm
                    title="Are you sure to reject this data?"
                    onConfirm={() => this.rejectData(v)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="danger">Reject</Button>
                </Popconfirm>
            }
        ];

        return (
            <div>
                <div className="advertisement2">
                </div>

                <header className="header2 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                            <div style={{ display: 'flex' }}>
                                <Link className="home-btn" to="/"><i className="fas fa-home"></i></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">

                                <ul className="navbar-nav ml-auto">

                                    <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Settings
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <Link className="dropdown-item" to="/grocery">Grocery</Link>
                                            <Link className="dropdown-item" to="/offer-type">OfferType</Link>
                                            <Link className="dropdown-item" to="/topic">Topic</Link>
                                            <Link className="dropdown-item" to="/user-group">UserGroup</Link>
                                            <Link className="dropdown-item" to="/product">Product</Link>
                                            <Link className="dropdown-item" to="/product-group">ProductGroup</Link>
                                            <Link className="dropdown-item" to="/product-type">ProductType</Link>
                                            <Link className="dropdown-item" to="/focusgroup">Focusgroup settings</Link>
                                            <Link className="dropdown-item" to="/1to1">1 To 1 Offer settings</Link>
                                            <Link className="dropdown-item" to="/ad">Promotional Ads settings</Link>
                                            <Link className="dropdown-item" to="/faq">FAQ</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/subscription">Subscriptions</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/insight">Insights</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li><li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>

                <div className="main-content-bg2">
                    <section>
                        <div className="center1">
                            <div className="card2">
                                <h2><b>OVERVIEW</b></h2>
                                <br />
                                <h4><b>Questions &amp; Answers</b></h4>
                                <br />
                                <br />
                                <Table
                                    className="components-table-demo-nested"
                                    onChange={this.handleChange}
                                    columns={columns}
                                    dataSource={tableData}
                                    onRow={(row, rowIndex) => this.handleExpand(row)}
                                    expandedRowRender={this.expandedRowRender}
                                    bordered
                                    title={() => <div className="table-operations">
                                        <Button onClick={this.setAgeSort}>Sort Name</Button>
                                        <Button onClick={this.clearAll}>Clear sorters</Button>
                                    </div>}
                                    style={{ width: '100%' }}
                                    tableLayout="fixed"
                                    scroll={{ x: 1000, y: 300 }}
                                />
                            </div>
                        </div>

                        <div className="center1">
                            <div className="card2">
                                <h2><b>OVERVIEW</b></h2>
                                <br />
                                <h4><b>Bu's Questions &amp; Answers</b></h4>
                                <br />
                                <br />
                                <Table
                                    className="components-table-demo-nested"
                                    onChange={this.handleChange}
                                    columns={columns1}
                                    dataSource={tableData1}
                                    // onRow={(row, rowIndex) => this.handleExpand(row)}
                                    // expandedRowRender={this.expandedRowRender}
                                    bordered
                                    title={() => <div className="table-operations">
                                        <Button onClick={this.setAgeSort}>Sort Name</Button>
                                        <Button onClick={this.clearAll}>Clear sorters</Button>
                                    </div>}
                                    style={{ width: '100%' }}
                                    tableLayout="fixed"
                                    scroll={{ x: 1000, y: 300 }}
                                />
                            </div>
                        </div>

                        <div className="center1">
                            <div className="card2">
                                <Form
                                    className="login-form form-w"
                                    hideRequiredMark={true}
                                    onSubmit={this.handleSubmit}
                                    encType="multipart/form-data">
                                    <h1 className="heading1" >Questions &amp; Answers Settings</h1>
                                    <Form.Item
                                        label="Topic"
                                    >
                                        {getFieldDecorator('topic', {
                                            rules: [{ required: true, message: 'Please Select Topic!' }],
                                        })(
                                            <Select
                                                showSearch
                                                mode="multiple"
                                                style={{ backgroundColor: '#fff' }}
                                                onSelect={() => this.checkLimit('topic')}
                                                placeholder="Select Topic"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    topicData.map((v, i) => {
                                                        return <Option value={v} key={i}>{v}</Option>
                                                    })
                                                }
                                            </Select>,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="BU"
                                    >
                                        {getFieldDecorator('buSwitch', {
                                            initialValue: false
                                        })(
                                            <Switch checked={buSwitch} onChange={e => this.setState({ buSwitch: e })} />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="CU"
                                    >
                                        {getFieldDecorator('cuSwitch', {
                                            initialValue: false
                                        })(
                                            <Switch checked={cuSwitch} onChange={e => this.setState({ cuSwitch: e })} />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Name"
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Please input Name!' }],
                                        })(
                                            <Input
                                                type="text"
                                                minLength={3}
                                                placeholder="Name"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Question"
                                    >
                                        {getFieldDecorator('question', {
                                            rules: [{ required: true, message: 'Please input Question!' }],
                                        })(
                                            <Input
                                                type="text"
                                                minLength={3}
                                                placeholder="Question"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Answer 1"
                                    >
                                        {getFieldDecorator('ans1', {
                                            rules: [{ required: true, message: 'Please input Answer 1!' }],
                                        })(
                                            <Input
                                                type="text"
                                                minLength={1}
                                                placeholder="Answer 1"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Answer 2"
                                    >
                                        {getFieldDecorator('ans2', {
                                            rules: [{ message: 'Please input Answer 2!' }],
                                        })(
                                            <Input
                                                type="text"
                                                minLength={1}
                                                placeholder="Answer 2"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Answer 3"
                                    >
                                        {getFieldDecorator('ans3', {
                                            rules: [{ message: 'Please input Answer 3!' }],
                                        })(
                                            <Input
                                                type="text"
                                                minLength={1}
                                                placeholder="Answer 3"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Answer 4"
                                    >
                                        {getFieldDecorator('ans4', {
                                            rules: [{ message: 'Please input Answer 4!' }],
                                        })(
                                            <Input
                                                type="text"
                                                minLength={1}
                                                placeholder="Answer 4"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Answer 5"
                                    >
                                        {getFieldDecorator('ans5', {
                                            rules: [{ message: 'Please input Answer 5!' }],
                                        })(
                                            <Input
                                                type="text"
                                                minLength={1}
                                                placeholder="Answer 5"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="submit-btn">
                                            <Button
                                                loading={loading}
                                                disable={disable.toString()}
                                                htmlType="submit"
                                                className="btn"
                                                style={{ backgroundColor: '#0587EF', color: 'white', fontWeight: 'bold', fontSize: 14, height: 50, display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                                                Save
                                        </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </section>
                    {showInspect ? <InspectModal visible={showInspect} inspectValue={inspectValue} disableModal={() => this.setState({ showInspect: false, inspectValue: {} })} handleOk={() => this.setState({ showInspect: false }, () => this.approvedData(inspectValue))} /> : null}
                    <Footer {...this.props} />
                </div>
            </div>
        )
    }
}

const QandaForm = Form.create({ name: 'normal_login' })(Qanda);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(QandaForm)