import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Descriptions, Avatar } from 'antd';
import { loginUser } from '../../Redux/actions/authActions'


class Inspect extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { visible, disableModal, inspectValue, handleOk } = this.props
        return (
            <div>
                <Modal
                    visible={visible}
                    title="1 To 1"
                    onOk={handleOk}
                    onCancel={disableModal}
                    footer={[
                        <Button key="back" onClick={disableModal}>
                            Return
                    </Button>,
                        <Button key="submit" type="primary" onClick={handleOk}>
                            Approve
                    </Button>,
                    ]}
                >
                    <Descriptions title="1 To 1 Detail" column={1} layout="horizontal" bordered>
                        <Descriptions.Item label="Name">{inspectValue.name}</Descriptions.Item>
                        <Descriptions.Item label="Focus Group">{inspectValue.focusGroup.join(" | ")}</Descriptions.Item>
                        <Descriptions.Item label="Product Name">{inspectValue.productName}</Descriptions.Item>
                        <Descriptions.Item label="Offer Type">{inspectValue.Offertype}</Descriptions.Item>
                        <Descriptions.Item label="Start Date">{inspectValue.startDate}</Descriptions.Item>
                        <Descriptions.Item label="End Date">{inspectValue.endDate}</Descriptions.Item>
                        <Descriptions.Item label="Description">{inspectValue.description}</Descriptions.Item>
                        <Descriptions.Item label="Image">
                            <Avatar src={inspectValue.image.url} size={100} />
                        </Descriptions.Item>
                    </Descriptions>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Inspect)