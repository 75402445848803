import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Descriptions, Avatar } from 'antd';
import { loginUser } from '../../Redux/actions/authActions'


class Inspect extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { visible, disableModal, inspectValue, handleOk } = this.props
        return (
            <div>
                <Modal
                    visible={visible}
                    title="Question And Answer"
                    onOk={handleOk}
                    onCancel={disableModal}
                    footer={[
                        <Button key="back" onClick={disableModal}>
                            Return
                    </Button>,
                        <Button key="submit" type="primary" onClick={handleOk}>
                            Approve
                    </Button>,
                    ]}
                >
                    <Descriptions title="Question And Answer Detail" column={1} layout="horizontal" bordered>
                        <Descriptions.Item label="Name">{inspectValue.name}</Descriptions.Item>
                        <Descriptions.Item label="Topic">{inspectValue.topic.join(" | ")}</Descriptions.Item>
                        <Descriptions.Item label="Question">{inspectValue.question}</Descriptions.Item>
                        <Descriptions.Item label="Answer 1">{inspectValue.ans1}</Descriptions.Item>
                        {inspectValue.ans2 && <Descriptions.Item label="Answer 2">{inspectValue.ans2}</Descriptions.Item>}
                        {inspectValue.ans3 && <Descriptions.Item label="Answer 3">{inspectValue.ans3}</Descriptions.Item>}
                        {inspectValue.ans4 && <Descriptions.Item label="Answer 4">{inspectValue.ans4}</Descriptions.Item>}
                        {inspectValue.ans5 && <Descriptions.Item label="Answer 5">{inspectValue.ans5}</Descriptions.Item>}
                    </Descriptions>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Inspect)